<template>
  <div class="tags-container">
    <Header />
    <el-main>
      <el-row justify="center" align="middle">
        <el-col :xs="22" :sm="20" :md="18" :lg="16" :xl="12">
          <el-breadcrumb separator="/" class="animated fadeInDown">
            <span style="float: left">当前位置：</span>
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>标签</el-breadcrumb-item>
          </el-breadcrumb>
          <div class="tags-content animated fadeInDown">
            <a
              href="javascript: void(0)"
              v-for="(tag, index) in tags"
              :key="index"
              :style="'background:' + this.randomColor()"
              @click="this.$router.push('/tag/' + tag.name)"
            >
              {{ tag.name }}
            </a>
          </div>
          <el-empty v-show="empty" description="没有标签信息" />
        </el-col>
      </el-row>
    </el-main>
  </div>
</template>

<script>
import Header from "@/components/common/header";
import { getTagsList } from "@/api/tag";
export default {
  name: "index",
  components: {
    Header,
  },
  data() {
    return {
      tags: [],
      empty: true,
    };
  },
  created() {
    this.getTagsList();
  },
  methods: {
    randomColor() {
      let colorArr = [
        "#1ABC9C",
        "#2ECC71",
        "#3498DB",
        "#9B59B6",
        "#34495E",
        "#F1C40F",
        "#E67E22",
        "#E74C3C",
        "#000",
      ];
      let index = Math.floor(Math.random() * colorArr.length);
      return colorArr[index];
    },
    getTagsList() {
      getTagsList().then((res) => {
        if (res.code == 200) {
          this.tags = res.data;
          this.empty = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-breadcrumb {
  padding: 20px;
}
.tags-container {
  width: 100%;
  height: 100%;
  color: var(--text-color);
}
.tags-content {
  position: relative;
  z-index: 99;
}
.tags-content a {
  display: inline-block;
  margin: 0.625rem;
  padding: 0 1rem;
  border-radius: 4px;
  text-decoration: none;
  color: #ffffff;
}
</style>
