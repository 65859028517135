import request from "@/utils/request";

export function getTagsList() {
  return request({
    url: "/getTagsList",
    method: "get",
  });
}

export async function getTag(data) {
  const name = data.name;
  const curPage = data.curPage;
  const pageSize = data.pageSize;
  return request({
    url: "/getTag/" + name,
    method: "get",
    params: {
      curPage,
      pageSize,
    },
  });
}
