<template>
  <el-header>
    <div class="cover">
      <el-image fit="cover" :src="navInfo.cover">
        <template #placeholder>
          <img style="height: 100%" src="@/assets/image/loading.gif" />
        </template>
        <template #error>
          <div class="image-slot">
            <i class="fa fa-image"></i>
          </div>
        </template>
      </el-image>
    </div>
    <div class="brand visible">
      <p class="title">
        <span class="title-head animated fadeInRight">『 </span>
        <span class="title-text animated fadeInDown">{{ navInfo.name }}</span>
        <span class="title-end animated fadeInLeft"> 』</span>
      </p>
    </div>
  </el-header>
</template>

<script>
export default {
  name: "header",
  data() {
    return {
      navInfo: {},
    };
  },
  created() {
    this.getNavInfo();
  },
  methods: {
    getNavInfo() {
      const code = this.$route.name;
      const menus = this.$store.getters.menus
      menus.forEach((item) => {
        if (item.code == code) {
          this.navInfo = item
        }
      })
    },
  },
};
</script>

<style scoped>
.el-header {
  position: relative;
  margin-bottom: 1rem;
  object-fit: cover;
  border: none;
  filter: brightness(0.9);
  height: 100%;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  border: none;
  text-align: center;
}

.cover .el-image {
  width: 100%;
  height: 30rem;
  object-fit: cover;
  border: none;
}

.brand {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  height: 30rem;
  text-shadow: 0 0.1875rem 0.3125rem #1c1f21;
  opacity: 0;
  border: none;
}

.brand p.title {
  position: relative;
  font-family: "BiaoSong", sans-serif;
  font-size: 3.2em;
  line-height: 1.2;
  z-index: 100;
}

.title span {
  display: inline-block;
  animate-duration: 2s;
}

@media screen and (max-width: 768px) {
  .brand p.title {
    font-size: 2.8rem;
  }
}
</style>
